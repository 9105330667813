<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del proveedor" :dense="true" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: $store.state.suppliers.retrieveLoader, text: instance.name }" :hiddenTab="true" :addPadding="true" :scroll="scroll" />
    <!-- end header -->
    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="$store.state.purchases.retrieveLoader" />
      <div class="d-flex" v-else>
        <PermissionDenied v-if="!$helpers.hasPermission(['view_supplier'])" />
        <template v-else>
          <!-- detail card -->
          <div class="mr-6" style="min-width: 400px;">
            <div style="position:fixed!important;">
              <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-supplier.svg`)">
                <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                  {{instance.name}}
                </span>
                <v-row class="ml-2 mt-3" align="end" no-gutters>
                  <v-col cols="5" class="py-0 px-2">
                    <span class="d-block body-1 white--text">RUT</span>
                    <span class="subtitle-2 white--text">
                      <span class="font-weight-bold">{{instance.tax_id}}</span>
                    </span>
                  </v-col>
                </v-row>
              </v-img>
              <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
                <v-toolbar color="transparent" flat height="46">
                  <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-bold">Resumen del proveedor</v-toolbar-title>
                </v-toolbar>
                <v-divider />

                <v-card-text class="py-4 px-5">
                  <v-list class="v-list-form-main transparent pa-0">
                    <v-list-item class="pa-0 ma-0" three-line>
                      <v-list-item-title class="secondary--text subtitle-2 d-flex align-center">Actividad económica</v-list-item-title>
                      <v-list-item-subtitle class="body-1">
                        <v-tooltip color="bgSearch" right max-width="185px" transition="scale-transition" open-delay="1000">
                        <template v-slot:activator="{ on }">
                          <span class="fontBody--text body-1" v-on="instance?.tax_settings?.cl_activity_description?.length > 40 ? on : false">
                            {{instance?.tax_settings?.cl_activity_description}}
                          </span>
                        </template>
                        <span class="d-block px-3 py-2">{{instance?.tax_settings?.cl_activity_description}}</span>
                      </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Moneda</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.currency}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Dirección</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.address || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Comuna</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.state || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <!-- <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Email</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.email || '-'}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Teléfono</v-list-item-title>
                      <v-list-item-subtitle>
                        <template v-if="instance.phone">{{instance.phone}}</template>
                        <span class="body-2 text--disabled font-italic" v-else>Sin número de teléfono</span>
                      </v-list-item-subtitle class="body-1">
                    </v-list-item> -->
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">ID</v-list-item-title>
                      <v-list-item-subtitle class="body-1">
                        <v-tooltip color="bgSearch" right transition="scale-transition">
                          <template v-slot:activator="{on}">
                            <v-btn class="fontDraw--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false" style="justify-content: left;">{{instance.id}}</v-btn>
                          </template>
                          <span class="d-block px-3 py-2">Copiar</span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Creado el</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.created | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pa-0">
                      <v-list-item-title class="fontBody--text subtitle-2">Actualizado el</v-list-item-title>
                      <v-list-item-subtitle class="body-1">{{instance.updated | dateTime}}</v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <!-- end summary -->

          <!-- panels -->
          <div style="flex:1; min-width: 200px;">
          <!-- related documents -->
          <v-card flat>
            <v-card-text class="pa-0">
                <v-row no-gutters align="center" justify="space-between">
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos recibidos</span>
                  </v-col>
                </v-row>
                <v-divider />
                <purchase-list :loading="purchaseLoading" :show-pagination="false" />
                <template v-if="count">
                  <v-divider />
                  <v-row class="px-4 py-4" align="center">
                    <v-btn class="ml-5 body-1" :to="{name: 'PurchaseList', query: { supplier: instance.id, ...(instance.name && { name: instance.name })} }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                  </v-row>
                </template>
              </v-card-text>
            </v-card>
            <!-- end related documents -->

            <!-- related documents -->
            <v-card flat class="mt-5" v-if="$store?.state?.auth?.account?.auto_purchase_sii">
              <v-card-text class="pa-0">
                  <v-row no-gutters align="center" justify="space-between">
                    <v-col class="px-5 py-4">
                      <span class="subtitle-2 secondary--text font-weight-bold"><v-icon size="18" left color="secondary">mdi-file-document-multiple</v-icon>Documentos pendientes de recibir</span>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <purchase-pending-list :loading="siiLoading" :show-pagination="false" />
                  <template v-if="count">
                    <v-divider />
                    <v-row class="px-4 py-4" align="center">
                      <v-btn class="ml-5 body-1" :to="{name: 'PurchaseList', query: { supplier: instance.id, ...(instance.name && { name: instance.name })} }" :ripple="false" text color="primary">Ver todos los documentos</v-btn>
                    </v-row>
                  </template>
                </v-card-text>
              </v-card>
              <!-- end related documents -->
          </div>
          <!-- end panels -->
        </template>
      </div>
    </v-col>

    <!-- dialog update supplier--
    <v-dialog v-if="instance.id" v-model="dialogSupplier" width="700" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="55">
            <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Editar proveedor</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-1">
                <v-btn class="ml-3" @click="dialogSupplier=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="v-list-form py-6 transparent">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.instance.name.$model" :error="$v.instance.name.$error" outlined required single-line dense placeholder="Acme LLC" maxlength="100" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>País</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="instance.country.name" disabled single-line :items="[instance.country.name]" item-text="name" item-value="id" hide-details dense height="30" outlined />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Estado</v-list-item-title>
              <v-list-item-subtitle>
                <v-col cols="8">
                  <v-select v-model="$v.instance.state.$model" :error="$v.instance.state.$error" :items="statesList" item-text="name" item-value="id" single-line hide-details dense height="30" outlined no-data-text="No existen estados para el país." />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Dirección</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model.trim="$v.instance.address.$model" :error="$v.instance.address.$error" outlined required single-line dense maxlength="64" hide-details/>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="text--secondary font-weight-medium">Información fiscal</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>
                ID Fiscal
                <v-tooltip color="bgSearch"  right max-width="280px" transition="scale-transition">
                  <template v-slot:activator="{on}">
                    <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                  </template>
                  <span class="d-block px-3 py-2">También llamado {{$t('generals.RUT')}}, VAT o Tax ID</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="8">
                  <v-text-field v-model.trim="instance.tax_id" :disabled="instance.country.has_regulation" outlined single-line dense hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item v-if="instance.country.id === 'CL'">
              <v-list-item-title>Actividad</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-text-field v-model.trim="$v.instance.tax_settings.cl_activity_description.$model" :error="$v.instance.tax_settings.cl_activity_description.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-4">
              <v-list-item-title>
                <span class="text--secondary font-weight-medium">Información de contacto</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="mt-2">
              <v-list-item-title>Email de emisión</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.instance.email.$model" :error="$v.instance.email.$error" outlined required single-line dense maxlength="64" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Teléfono</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.instance.phone.$model" :error="$v.instance.phone.$error" outlined required single-line dense v-if="instance.country" v-mask="`${instance.country.phone_code} # #### ####`" maxlength="16" hide-details />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Sitio web</v-list-item-title>
              <v-list-item-subtitle class="py-1">
                <v-col cols="8">
                  <v-text-field v-model.trim="$v.instance.website.$model" :error="$v.instance.website.$error" outlined required single-line class="pa-0" dense  maxlength="64" :hide-details="true" />
                </v-col>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-4">
          <v-spacer />
          <v-btn outlined @click="dialogSupplier=false">Cancelar</v-btn>
          <v-btn @click="updateSupplier" :loading="$store.state.suppliers.createLoader" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!- dialog update supplier -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import MiniHeader from '@/components/commons/MiniHeader'
  import cloneDeep from 'lodash/cloneDeep'
  import { required, requiredIf, email, url, maxLength } from 'vuelidate/lib/validators'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import PurchaseList from '@/modules/purchase/components/PurchaseList'
  import PurchasePendingList from '@/modules/purchase/pending-receive/components/PendingReceiveList'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import PermissionDenied from '@/modules/http/views/PermissionDenied'
  // import DocumentTypes from '@/collections/documentTypes'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      PurchaseList,
      MiniHeader,
      PermissionDenied,
      PurchasePendingList
    },
    data: () => ({
      scroll: 0,
      comments: '',
      dialogSwap: false,
      loading: false,
      panelIrsSii: [],
      panelComments: false,
      panelEmail: false,
      panelPDF: false,
      panelRelatedDocuments: 0,
      isAccept: false,
      purchaseLoading: false,
      siiLoading: false,
      dialogConfirm: false,
      currentList: [],
      current: {},
      env: process.env?.VUE_APP_MODE ?? 'ax',
      breadcrumbs: {
        main: 'Recepción',
        children: [
          {
            text: 'Proveedores',
            route: 'SupplierListCreate'
          }
        ]
      }
  }),
  computed: mapState({
    statesList: state => state.suppliers.statesList,
    count: state => state.purchases.purchasesCount,
    purchasesList: state => state.purchases.purchasesList,
    instance: state => state.suppliers.suppliersDetail
  }),
  created () {
    this.loading = true
    this.$store.dispatch('suppliers/RETRIEVE', {
      resource: 'suppliers',
      id: this.$route.params.id
    })
    .then(() => {
      const supplier = cloneDeep(this.instance)
      this.editable = supplier
      this.getContacts()
    })
    .finally(() => {
      this.loading = false
    })
    if (this.$helpers.hasPermission(['view_purchase'])) {
      this.purchaseLoading = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'purchases',
        query: {
          supplier: this.$route.params.id,
          page_size: 6
        }
      })
      .finally(() => {
        this.purchaseLoading = false
      })
      this.siiLoading = true
      this.$store.dispatch('purchases/LIST', {
        resource: 'purchases_from_sii',
        query: {
          supplier: this.$route.params.id,
          page_size: 6
        }
      })
      .finally(() => {
        this.siiLoading = false
      })
    }
  },
  methods: {
    onScroll (e) {
      this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
    },
    updateSupplier () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }
      const payload = {
        name: this.editable.name,
        state: this.editable.state.id || this.editable.state,
        address: this.editable.address,
        tax_settings: this.editable.tax_settings,
        email: this.editable.email,
        phone: this.editable.phone,
        website: this.editable.website
      }
      if (!this.instance.country.has_regulation) {
        payload.tax_id = this.editable.tax_id
      }
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers',
        id: this.$route.params.id,
        payload: payload
      })
      .then(() => {
        this.dialogSupplier = false
        this.$dialog.message.info('El proveedor ha sido actualizado')
      })
      .catch(() => {
        this.$dialog.message.error('Error al actualizar el proveedor.')
      })
    }
  },
  validations: {
    editable: {
      name: {
        required,
        maxLength: maxLength(100)
      },
      state: {
        required
      },
      address: {
        required,
        maxLength: maxLength(64)
      },
      tax_settings: {
        cl_activity_description: {
          required: requiredIf(function () {
            return this.editable.country.id === 'CL'
          }),
          maxLength: maxLength(64)
        }
      },
      tax_id: {
        maxLength: maxLength(32)
      },
      email: {
        required,
        email,
        maxLength: maxLength(254)
      },
      phone: {
        maxLength: maxLength(16)
      },
      website: {
        url
      }
    }
  }
}
</script>
<style scoped>
.v-list--three-line .v-list-item .v-list-item__subtitle, .v-list-item--three-line .v-list-item__subtitle {
  -webkit-line-clamp: 2 !important;
}
.v-list--three-line .v-list-item, .v-list-item--three-line {
  min-height: auto;
}
</style>